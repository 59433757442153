import { Node } from "tiptap";

export default class Iframe extends Node {
  get name() {
    return "iframe";
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null
        },
        youtube: {
          default: null
        }
      },
      group: "block",
      selectable: false,
      parseDOM: [
        {
          tag: "iframe",
          getAttrs: dom => ({
            src: dom.getAttribute("src"),
          })
        },
      ],
      toDOM: node => [
        "div",
        {style: "text-align: center"},
        [
          "iframe",
          {
            src: node.attrs.src,
            class: 'youtube',
            frameborder: 0,
            allowfullscreen: "true",
            style: "max-width: 100%",
            width: "580",
            height: "400",
            allow:
              "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          },
        ],
      ]
    };
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }
}
