import { Link } from 'tiptap-extensions'
import { updateMark, removeMark } from 'tiptap-commands'

export default class CustomLink extends Link {
  get schema() {
    return {
      attrs: {
        href: {
            default: null,
        },
        target: {
            default: null,
        },
        rel: {
          default: null
        }
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => ({
            href: dom.getAttribute('href'),
            target: dom.getAttribute('target'),
            rel: dom.getAttribute('rel')
          }),
        },
      ],
      toDOM: node => ['a', {
        ...node.attrs,
      }, 0],

    }
  }
  commands({ type }) {
    return attrs => {
      if (attrs.href) {
        return updateMark(type, attrs)
      }

      return removeMark(type)
    }
  }
}