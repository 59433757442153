import { Node } from "tiptap";

export default class CustomImage extends Node {
  get name() {
    return "customImage";
  }

  get schema() {
    return {
      content: "inline*",
      attrs: { 
        srcset: {}, 
        title: {}, 
        alt: {},
      },
      group: 'block',
      draggable: true,
      selectable: true,
      parseDOM: [{
        tag: "figure",
        contentElement: "figcaption",
        getAttrs(dom) {
          const img = dom.querySelector("img");
          return { 
            srcset: img.getAttribute('srcset'), 
            title: img.getAttribute('title'), 
            alt: img.getAttribute('alt') 
          }
        }
      }],
      toDOM: node => ["figure", ["img", node.attrs], ["figcaption", node.attrs.title]],
    }
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }
}
