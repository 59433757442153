<script>

export default {
  data() {
    return {
      code: "",
      command: null,
      show: false,
    };
  },

  computed: {
    soundcloudSrc() {
      let src = '';
      if(this.code) {
        let element = document.createElement('div');
        element.innerHTML = this.code;
        element = element && element.firstElementChild ? element.firstElementChild : '';
        src = element ? element.getAttribute('src') : '';
      }
      return src;
    }
  },

  methods: {

    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
      
    },

    insertSound() {
      // Some check can be done here, like if `soundcloudSrc` is not false.
      const data = {
        command: this.command,
        data: {
          src: this.soundcloudSrc,
          soundcloud: true,
        }
      };
      this.soundcloudSrc && this.$emit("onConfirm", data);
      this.code = '';
      this.show = false;
    }
  }
};

</script>

<template>
  <div
    v-if="show"
    class="modal"
  >
    <div class="modalContent">
      <h3>{{ $t('addItem', { item: `Soundcloud ${$t('player')}` }) }}</h3>
      <div class="inputArea">
        <label
          class="label"
          for="code"
        >{{ $t('pasteYourSoundcloudCode') }}: &nbsp;</label>
        <input
          id="code"
          ref="code"
          v-model="code"
          class="code"
          autofocus
        >
      </div>
      <footer class="modalFooter">
        <button
          class="button closeButton"
          @click="show = false; code = '';"
        >
          {{ $t('close') }}
        </button>
        <button
          :disabled="!soundcloudSrc"
          class="button confirmButton"
          @click="insertSound"
        >
          {{ $t('confirm') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($c-black, 0.5)
  &Content
    padding: $s-xl
    background: $c-pickled-bluewood-blue
    border-radius: $base-border-radius
    box-shadow: $base-shadow
  &Footer
    margin-top: $s-xl
    text-align: right

.inputArea
  display: flex
  align-items: center

.label
  margin-bottom: 0
  font-size: 14px

.code
  min-width: 250px
  height: 40px
  padding: 0
  border: none
  border-radius: $base-border-radius
  box-shadow: $base-shadow

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing
</style>
