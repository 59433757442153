<script>
import { EditorMenuBubble } from 'tiptap'
import SearchSelectList from '@/components/SearchSelectList'

export default {
  components: {
    EditorMenuBubble,
    SearchSelectList,
  },

  props: {
    editor: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      superValue: null,
      isLinkBlank: false,
      linkContainsRels: false,
      linkMenuIsActive: false,
      activeType: '', // 'link', 'affiliate'
      afid: '',
    }
  },

  methods: {
    showBubbleMenu(attrs, type) {
      this.superValue = attrs.href
      this.linkContainsRels = !!attrs.rel
      this.isLinkBlank = attrs.target && attrs.target === '_blank'
      this.activeType = type
      this.linkMenuIsActive = true
    },
    hideBubbleMenu() {
      this.superValue = null
      this.linkContainsRels = false
      this.isLinkBlank = false
      this.linkMenuIsActive = false
      this.activeType = ''
    },
    setLinkUrl(command, { url, newWindow = this.isLinkBlank, hasRel = this.linkContainsRels }) {
      command({ href: url, target: newWindow ? '_blank' : '_self', rel: hasRel ? 'noopener noreferrer nofollow' : '' })
      this.hideBubbleMenu();
    },
    valueChanged(newValue) {
      this.superValue = newValue
    },
    setAffiliate(command, { afid }) {
      command({ afid: afid, class: 'affiliate', flag: 'true' })
      this.hideBubbleMenu()
    },
    affiliateChanged(newValue) {
      this.afid = newValue
    }
  }
}
</script>

<template>
  <div>
    <EditorMenuBubble
      v-slot="{ commands, isActive, getMarkAttrs, menu }"
      :editor="editor"
    >
      <div
        class="menububble text-center"
        :class="{ menububbleIsActive: menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <form
          v-if="linkMenuIsActive"
          class="menububbleForm form"
        >
          <div class="linkBubbleButtons">
            <div>
              <div>
                <BaseInput
                  label="New Window"
                  is-slider-checkbox
                >
                  <input
                    v-model="isLinkBlank"
                    class="form-control"
                    type="checkbox"
                    :true-value="true"
                    :false-value="false"
                  >
                </BaseInput>
              </div>
            </div>
            <div>
              <div>
                <BaseInput
                  label="External Link"
                  is-slider-checkbox
                >
                  <input
                    v-model="linkContainsRels"
                    class="form-control"
                    type="checkbox"
                    :true-value="true"
                    :false-value="false"
                  >
                </BaseInput>
              </div>
            </div>
          </div>
          <div class="menububbleFormContainer">
            <SearchSelectList
              class="searchResults"
              :type="activeType"
              :preset-keyword="superValue"
              @keydown.esc="hideBubbleMenu"
              @change="valueChanged"
            />
          </div>
          <footer class="menububbleFooter">
            <button
              class="button closeButton"
              @click="hideBubbleMenu"
            >
              {{ $t('close') }}
            </button>
            <button
              v-if="activeType === 'link'"
              class="button confirmButton"
              @click="setLinkUrl(commands.link, {
                url: superValue,
                newWindow: isLinkBlank,
                hasRel: linkContainsRels,
              })"
            >
              {{ $t('confirm') }}
            </button>
            <button
              v-if="activeType === 'affiliate'"
              class="button confirmButton"
              @click="setAffiliate(commands.affiliate, {
                afid: superValue,
              })"
            >
              {{ $t('confirm') }}
            </button>
          </footer>
        </form>

        <template v-else>
          <div class="menububbleButtons">
            <div
              :class="['menububbleButton', { isActive: isActive.link() }]"
              @click="showBubbleMenu(getMarkAttrs('link'), 'link')"
            >
              <span>{{ isActive.link() ? $t('updateItem', { item: $tc('link') }) : $t('addItem', { item: $tc('link') }) }}</span>
              <BaseIcon
                class="menububbleIcon"
                icons-group="fas"
                icon="fa-link"
              />
            </div>
            <div
              :class="['menububbleButton', { isActive: isActive.affiliate() }]"
              @click="showBubbleMenu(getMarkAttrs('affiliate'), 'affiliate')"
            >
              <span>{{ isActive.affiliate() ? $t('updateItem', { item: $tc('affiliate') }) : $t('addItem', { item: $tc('affiliate') }) }}</span>
              <BaseIcon
                class="menububbleIcon"
                icons-group="fas"
                icon="fa-comments-dollar"
              />
            </div>
          </div>
        </template>
      </div>
    </EditorMenuBubble>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.menububble
  position: absolute
  z-index: 100
  display: flex
  align-items: center
  justify-content: center
  min-width: 330px
  max-width: 100%
  min-height: 50px
  padding: $s-xs $base-spacing
  background: $dark-gradient
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  opacity: 0
  transition: opacity .3s ease-in-out
  transform: scale(0)
  &IsActive
    opacity: 1
    transform: scale(1)
  &Button
    display: flex
    cursor: pointer
  &Icon
    margin-left: $base-spacing    
  &Footer
    margin: $s-xl 0
    text-align: right

.linkBubbleButtons
  display: grid
  grid-template-columns: 1fr 1fr
  align-items: center
  margin: $s-l 0

.menububbleButton:hover
  color: $c-acc-green

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing

</style>