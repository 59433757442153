import { Node } from "tiptap";

export default class Soundcloud extends Node {
  get name() {
    return "soundcloud";
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null
        },
        soundcloud: {
          default: null
        }
      },
      group: "block",
      selectable: false,
      parseDOM: [
        {
          tag: "iframe.soundcloud",
          getAttrs: dom => ({
            src: dom.getAttribute("src"),
          })
        },
      ],
      toDOM: node => [
        "div",
        { style: "text-align: center" },
        [
          "iframe",
          {
            src: node.attrs.src,
            class: 'soundcloud',
            width: "100%",
            height:"166",
            scrolling: "no",
            frameborder: "no",
            allow: "autoplay",
          },
        ],
      ]
    };
  }

  commands({ type }) {
    return attrs => (state, dispatch) => {
      const { selection } = state;
      const position = selection.$cursor
        ? selection.$cursor.pos
        : selection.$to.pos;
      const node = type.create(attrs);
      const transaction = state.tr.insert(position, node);
      dispatch(transaction);
    };
  }
}
