<script>
import { DOMParser } from 'prosemirror-model'

export default {
  data() {
    return {
      code: "",
      editor: null,
      show: false,
    };
  },

  methods: {
    showModal(editor) {
      this.editor = editor;
      this.show = true;
    },

    insertSourceCode() {
      this.code && this.insertHTML(this.editor, this.code);
      this.code = '';
      this.show = false;
    },

    elementFromString(value) {
      const element = document.createElement('div');
      element.innerHTML = value.trim();
      return element;
    },

    insertHTML({ state, view }, value) {
      const { selection } = state;
      const element = this.elementFromString(value);
      const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
      const transaction = state.tr.insert(selection.anchor, slice.content);
      view.dispatch(transaction);
    }
  }
};

</script>

<template>
  <div
    v-if="show"
    class="modal"
  >
    <div class="modalContent">
      <h3>{{ $t('addItem', { item: $t('sourceCode') }) }}</h3>
      <div class="inputArea">
        <label
          class="label"
          for="code"
        >{{ $t('pasteYourSourceCode') }}: &nbsp;</label>
        <input
          id="code"
          ref="code"
          v-model="code"
          class="code"
          autofocus
        >
      </div>
      <footer class="modalFooter">
        <button
          class="button closeButton"
          @click="show = false; code = '';"
        >
          {{ $t('close') }}
        </button>
        <button
          :disabled="!code"
          class="button confirmButton"
          @click.prevent="insertSourceCode"
        >
          {{ $t('confirm') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($c-black, 0.5)
  &Content
    padding: $s-xl
    background: $c-pickled-bluewood-blue
    border-radius: $base-border-radius
    box-shadow: $base-shadow
  &Footer
    margin-top: $s-xl
    text-align: right

.inputArea
  display: flex
  align-items: center

.label
  margin-bottom: 0
  font-size: 14px

.code
  min-width: 250px
  height: 40px
  padding: 0
  border: none
  border-radius: $base-border-radius
  box-shadow: $base-shadow

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing
</style>
