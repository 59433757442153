<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Tab',
        },
    },
    data() {
        return {
            isActive: true,
        }
    },
}
</script>

<template lang="html">
  <div
    v-show="isActive"
    class="tab"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/sass/design';

.tab {
    display: inline-block;
    width: 100%;
    min-height: 400px;
    padding: $s-m;
    color: $c-white;
    background-color: transparentize($c-pickled-bluewood-blue, 0.05);
    border-radius: 6px;
}
</style>
