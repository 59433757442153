import { get } from '@/services/api.service';

const API_URL = '/search';

class SearchService {
  search(keyword) {
    return keyword.length > 2 ? get(`${API_URL}/${keyword}?extended=1`)
      .then(res => res) : false;
  }
}

export default new SearchService();