<script>

export default {
  data() {
    return {
      url: "",
      command: null,
      show: false,
    };
  },

  computed: {
    youtubeID() {
      return this.youtubeParser(this.url);
    }
  },

  methods: {
    youtubeParser(url) {
      // eslint-disable-next-line no-useless-escape
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : false;
    },

    showModal(command) {
      // Add the sent command
      this.command = command;
      this.show = true;
      
    },

    insertVideo() {
      // Some check can be done here, like if `youtubeID` is not false.
      const data = {
        command: this.command,
        data: {
          src: `https://www.youtube-nocookie.com/embed/${this.youtubeID}`,
          class: 'youtube',
        }
      };
      this.youtubeID && this.$emit("onConfirm", data);
      this.url = '';
      this.show = false;
    }
  }
};

</script>

<template>
  <div
    v-if="show"
    class="modal"
  >
    <div class="modalContent">
      <h3>{{ $t('addItem', { item: `Youtube ${$tc('video')}` }) }}</h3>
      <div class="inputArea">
        <label
          class="label"
          for="url"
        >{{ $t('pasteYoutubeURL') }}: &nbsp;</label>
        <input
          id="url"
          ref="url"
          v-model="url"
          class="url"
          autofocus
        >
      </div>
      <footer class="modalFooter">
        <button
          class="button closeButton"
          @click="show = false; url = '';"
        >
          {{ $t('close') }}
        </button>
        <button
          :disabled="!youtubeID"
          class="button confirmButton"
          @click="insertVideo"
        >
          {{ $t('confirm') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.modal
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($c-black, 0.5)
  &Content
    padding: $s-xl
    background: $c-pickled-bluewood-blue
    border-radius: $base-border-radius
    box-shadow: $base-shadow
  &Footer
    margin-top: $s-xl
    text-align: right

.inputArea
  display: flex
  align-items: center

.label
  margin-bottom: 0
  font-size: 14px

.url
  min-width: 250px
  height: 40px
  padding: 0
  border: none
  border-radius: $base-border-radius
  box-shadow: $base-shadow

.button
  padding: $base-spacing / 3 $base-spacing
  color: $c-white
  cursor: pointer
  border-radius: $base-border-radius
  box-shadow: $base-shadow
  transition: all .1s ease-out
  &:hover
    opacity: 0.9
  &:active
    transform: scale(0.9)

.confirmButton
  background: $c-acc-green
  &:disabled
    color: $text-faded
    background: $c-acc-green-dark

.closeButton
  background: $c-acc-red

.button + .button
  margin-left: $base-spacing
</style>
