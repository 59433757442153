import { Link } from 'tiptap-extensions'
import { updateMark, removeMark } from 'tiptap-commands'

export default class Affiliate extends Link {

  get name() {
    return "affiliate";
  }

  get schema() {
    return {
      attrs: {
        afid: {
            default: null,
        },
        class: {
          default: null,
        },
        flag: {
            default: null,
        },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'affiliate',
          getAttrs: dom => ({
            afid: dom.getAttribute('afid'),
            class: dom.getAttribute('class'),
            flag: dom.getAttribute('flag'),
          }),
        },
      ],
      toDOM: node => ['affiliate', {
        ...node.attrs,
      }, 0],

    }
  }
  commands({ type }) {
    return attrs => {
      if (attrs.afid) {
        return updateMark(type, attrs)
      }

      return removeMark(type)
    }
  }
}