<script>
import { SeoAssessor, ContentAssessor, SnippetPreview } from './../../plugins/vue-yoast/src'
import webappRoutes from './../data/enums/webappRoutes'
import TextEditor from './../components/TextEditor/TextEditor'
import Modal from './../components/Modal'
import LibraryList from './../components/LibraryList'
import Tabs from './../components/Tabs/Tabs'
import Tab from './../components/Tabs/Tab'

export default {
  name: 'EditLayout',
	components: {
		TextEditor,
		Modal,
		LibraryList,
    Tabs,
    Tab,
    SeoAssessor,
    ContentAssessor,
    SnippetPreview,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		submitButtonText: {
			type: String,
			default: "",
		},
    photo: {
      type: Object,
      default: () => {}
    },
    supportsPhoto: {
      type: Boolean,
      default: false
    },
    supportsEditor: {
      type: Boolean,
      default: true
    },
	},
	data() {
		return {
			isLibraryModalVisible: false,
			selectedNewPhoto: null,
      displayedPhoto: null,
      isAliasEditable: false,
      focusKeyword: '',
      webappRoutes
		}
	},
	watch: {
		photo() {
			this.displayedPhoto = this.photo
		},
  },
	methods: {
		openLibrary() {
			this.isLibraryModalVisible = true
		},
		removePhoto() {
			this.displayedPhoto = null
			this.updateLibraryId(null)
		},
		updateMainPhoto(items) {
			this.selectedNewPhoto = items[0]
		},
		confirmNewPhoto() {
			if(this.selectedNewPhoto) {
        this.updateLibraryId(this.selectedNewPhoto.id)
        this.displayedPhoto = this.selectedNewPhoto
        this.selectedNewPhoto = null
      }
			this.isLibraryModalVisible = false
		},
		updateLibraryId(newVal) {
			let el = this.$refs.libraryModel
			el.value = newVal
			el.dispatchEvent(new Event('input'))
		},
		updateSelectedConsoles(newVal) {
			this.$emit('updateSelectedConsoles', newVal)
    },
    validLength(min, max, current) {
      return current < min ? 'lower' : current > max ? 'higher' : 'valid'
    },
    assessorResultFilter (value) {
      return value
    }
	},
}
</script>

<template>
  <div class="card editor">
    <div class="card-body">
      <form
        autocomplete="off"
        class="form formArea"
      >
        <div class="main">
          <div class="title">
            <div
              class="alias"
            >
              <BaseInput
                v-if="isAliasEditable"
                v-model="item.alias"
                class="aliasEditable"
                :placeholder="`${$t('enter')} ${$tc('alias')}`"
              />
              <h6
                v-else
                class="aliasSubtitle"
              >
                {{ $tc('alias') }}: {{ item.alias }}
              </h6>

              <BaseButton
                class="aliasButton"
                size="sm"
                @click="isAliasEditable = !isAliasEditable"
              >
                {{ isAliasEditable ? $t('close') : `${$t('edit')} ${$tc('alias')}` }}
              </BaseButton>
            </div>
            <h1
              v-if="$slots.title"
              class="title"
            >
              <slot name="title" />
            </h1>
            <h1 class="title">
              <input
                v-if="item && 'name' in item"
                v-model="item.name"
                :placeholder="`${$t('enter')} ${$t('title')} ${$t('here')}...`"
              >
            </h1>
          </div>
          <div class="subtitle">
            <slot name="subtitle" />
          </div>
          <BaseInput v-if="supportsEditor">
            <TextEditor v-model="item.description" />
          </BaseInput>
          <slot name="middle" />
          <slot name="bottom" />
          <div v-if="['post', 'console', 'accessory', 'page'].includes(item.type)">
            <BaseInput
              v-model="focusKeyword"
              label="Focus Keyword"
            />

            <Tabs>
              <Tab title="SEO">
                <SeoAssessor
                  :keyword="focusKeyword"
                  :title="item.name"
                  :title-width="item.name.length"
                  :description="item.description_socials"
                  :text="displayedPhoto ? `${item.description}<img src='
                  ${displayedPhoto.photo.large.URL}' alt='${displayedPhoto.photo.alt}'>` : item.description"
                  locale="en_US"
                  :permalink="`https://gamemedium.com/${webappRoutes[item.type]}/${item.alias}`"
                  :url="`${webappRoutes[item.type]}/${item.alias}`"
                  :result-filter="assessorResultFilter"
                />
              </Tab>
              <Tab title="Content">
                <ContentAssessor
                  :title="item.name"
                  :title-width="item.name.length"
                  :description="item.description_socials"
                  :url="`/${webappRoutes[item.type]}/${item.alias}`"
                  :text="item.description"
                  locale="en_US"
                  :result-filter="assessorResultFilter"
                />
              </Tab>
              <Tab title="Snippet Preview">
                <SnippetPreview
                  :title="item.name"
                  :description="item.description_socials"
                  :url="`/${webappRoutes[item.type]}/${item.alias}`"
                  base-url="https://gamemedium.com"
                  style="background: white; padding: 15px; border-radius: 6px;"
                />
              </Tab>
            </Tabs>
          </div>
        </div>
        <div class="side">
          <div class="sideInner">
            <slot name="sidebar-top" />
            <BaseButton
              slot="footer"
              type="green"
              gradient
              fill
              class="saveButton"
              @click="$emit('submit')"
            >
              {{ submitButtonText }}
            </BaseButton>
            <div v-if="item && item.hasOwnProperty('published')">
              <BaseInput
                :label="`${$t('active')}/${$t('published')}`"
                is-slider-checkbox
              >
                <input
                  v-model="item.published"
                  class="form-control"
                  type="checkbox"
                  true-value="1"
                  false-value="0"
                >
              </BaseInput>
            </div>
            <slot name="sidebar-middle" />
            <div
              v-if="supportsPhoto"
              class="photo"
            >
              <input
                ref="libraryModel"
                v-model="item.library_id"
                type="hidden"
              >
              <label>{{ $tc('photo') }}</label>
              <div
                v-if="displayedPhoto"
                class="photoContainer"
              >
                <BaseImage
                  :source="displayedPhoto"
                  size="thumb"
                />
                <div class="photoOverlay text-center">
                  <div>
                    <BaseIcon
                      icons-group="far"
                      icon="fa-edit"
                      class="icon iconHover iconEdit"
                      size="l"
                      @click="openLibrary"
                    />
                    <div>{{ $t('edit') }}</div>
                  </div>
                  <div>
                    <BaseIcon
                      icons-group="fas"
                      icon="fa-ban"
                      class="icon iconHover iconDelete"
                      size="l"
                      @click="removePhoto"
                    />
                    <div>{{ $t('remove') }}</div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="photoContainer"
              >
                <div class="photoOverlay">
                  <div class="text-center">
                    <BaseIcon
                      icons-group="fas"
                      icon="fa-plus-circle"
                      class="icon iconHover iconAdd"
                      size="xl"
                      @click="openLibrary"
                    />
                    <div>{{ $t('addItem', { item: $tc('photo')}) }}</div>
                  </div>
                </div>
                <div class="photoAlt">
                  <BaseIcon
                    icons-group="far"
                    icon="fa-image"
                    class="icon iconImage"
                    size="xxxl"
                    fill
                  />
                </div>
              </div>
            </div>
            <div class="sideBottom">
              <div
                v-if="item && 'seo_title' in item"
                class="metaTitle"
              >
                <label>SEO {{ $t('title') }} ({{ $t('min') }} 40) <span :class="[validLength(40, 70, item.seo_title ? item.seo_title.length : '0')]">{{ item.seo_title ? item.seo_title.length : '0' }}/70</span></label>
                <BaseInput
                  v-model="item.seo_title"
                  :placeholder="`${$t('enter')} SEO ${$t('title')} ${$t('here')}...`"
                />
              </div>
              <div
                v-if="item && 'description_socials' in item"
                class="metaDescription form-group"
              >
                <label>SEO {{ $t('description') }} ({{ $t('min') }} 50) <span :class="[validLength(50, 160, item.description_socials ? item.description_socials.length : '0')]">{{ item.description_socials ? item.description_socials.length : '0' }}/160</span></label>
                <textarea
                  v-model="item.description_socials"
                  class="form-control"
                  cols="30"
                  rows="10"
                  :placeholder="`${$t('enter')} SEO ${$t('description')} ${$t('here')}...`"
                />
              </div>
              <slot
                name="sidebar-bottom"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <Modal
      :show.sync="isLibraryModalVisible"
      :centered="true"
      :show-close="true"
    >
      <LibraryList
        v-if="isLibraryModalVisible"
        :limit="40"
        select-items="single"
        class="library"
        grid-item-size="100"
        :preselected-items="displayedPhoto ? [displayedPhoto] : []"
        fixed-type="photos"
        @updateSelected="updateMainPhoto"
      />
      <div class="libraryButton text-right">
        <BaseButton
          type="green"
          gradient
          @click="confirmNewPhoto"
        >
          {{ $t('confirm') }}
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
@use '~@/assets/sass/design.sass' as *;

.editor {
  padding-top: $base-spacing;
}

.form {
  &Area {
    display: grid;
    grid-gap: $base-spacing;
  }
}

.alias {
  display: flex;
  align-items: center;

  &Editable {
    margin: 0 $base-spacing 0 0;
  }

  &Subtitle {
    margin: 0 $base-spacing 0 0;
    color: $c-acc-green;
    text-transform: initial;
  }
}

.title {
  margin-bottom: 0;

  input {
    width: 100%;
    padding: $s-s $s-xs;
    margin-bottom: $base-spacing;
    font-weight: 100;
    color: $c-white;
    background: none;
    border: 1px solid transparent;
    border-bottom: 1px solid lighten($c-pickled-bluewood-blue, 5%);

    &:focus {
      border-color: transparentize($c-acc-pink, 0.8);
      border-radius: 6px;
      outline: 0;
    }
  }
}

.side {
  &Inner {
    position: sticky;
    top: $base-spacing;
  }

  &Bottom {
    margin-top: $base-spacing;
  }
}

.saveButton {
  margin-bottom: $s-s;
}

.photo {
  position: relative;

  &Alt {
    text-align: center;
  }

  &Container {
    position: relative;
    padding: $base-spacing;
  }

  &Button {
    margin-top: $base-spacing;
  }

  &Overlay {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    color: $c-white;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    @include center;

    &::before {
      z-index: -1;
      width: 100%;
      height: 100%;
      content: '';
      background: $green-blue-purple-gradient;
      border-radius: 8px;
      opacity: 0.8;
      @include center;
    }
  }

  &:hover &Overlay {
    opacity: 1;
  }
}

.library {
  max-height: 85vh;
  overflow: auto;

  &Button {
    padding: $base-spacing;
  }
}

.icon {
  &Add {
    color: $c-acc-green;
  }

  &Delete {
    color: $c-acc-red;
  }

  &Edit {
    color: $c-san-juan-blue;
  }

  &Hover {
    cursor: pointer;
  }
}

.lower {
  color: $text-faded;
}

.higher {
  color: $c-acc-red;
}

.valid {
  color: $c-acc-green;
}

@include desktop {
  .form {
    &Area {
      grid-template-columns: 3fr 1fr;
    }
  }
}
</style>
