import {
	Blockquote,
	HardBreak,
	Heading,
	HorizontalRule,
  OrderedList,
	BulletList,
	ListItem,
	Bold,
	Italic,
	Strike,
  Underline,
	History,
  Image,
  TrailingNode,
  Placeholder,
} from 'tiptap-extensions'
import Soundcloud from '@/components/TextEditor/CustomExtensions/soundcloud.editor.extension'
import Iframe from '@/components/TextEditor/CustomExtensions/iframe.editor.extension'
import CustomLink from '@/components/TextEditor/CustomExtensions/customLink.editor.extensions'
import CustomImage from '@/components/TextEditor/CustomExtensions/customImage.editor.extensions'
import Affiliate from '@/components/TextEditor/CustomExtensions/affiliate.editor.extensions'
import Paragraph from './CustomExtensions/alignment.editor.extensions'

export default [
  new Blockquote(),
  new BulletList(),
  new HardBreak(),
  new Heading({ levels: [2, 3, 4, 5, 6] }),
  new HorizontalRule(),
  new ListItem(),
  new OrderedList(),
  new Bold(),
  new Italic(),
  new Strike(),
  new Underline(),
  new History(),
  new Image(),
  new TrailingNode(),
  new Placeholder({
    emptyEditorClass: 'isEditorEmpty',
    showOnlyWhenEditable: true,
    showOnlyCurrent: true,
  }),
  // custom extensions
  new Soundcloud(),
  new Iframe(),
  new CustomLink(),
  new CustomImage(),
  new Affiliate(),
  new Paragraph()
]
