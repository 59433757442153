<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import VideoModal from '@/components/TextEditor/VideoModal'
import SoundcloudModal from '@/components/TextEditor/SoundcloudModal'
import SourceCodeModal from '@/components/TextEditor/SourceCodeModal'
import extensions from '@/components/TextEditor/extensions.js'
import Modal from '@/components/Modal'
import LibraryList from '@/components/LibraryList'
import TextEditorLink from '@/components/TextEditor/TextEditor.Link'
import LazyLoad from '@/directives/LazyLoad'

export default {
  components: {
    EditorContent,
    EditorMenuBar,
    VideoModal,
    SoundcloudModal,
    SourceCodeModal,
    Modal,
    LibraryList,
    TextEditorLink,
  },
  directives: {
		lazyLoad: LazyLoad,
	},
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emitAfterOnUpdate: false,
      isLibraryModalVisible: false,
      selectedPhoto: null,
      command: null,
      editor: new Editor({
        onUpdate: ({ getHTML }) => {
          this.emitAfterOnUpdate = true;
          this.$emit("input", getHTML());
        },
        content: this.value,
        extensions
      }),
    }
  },
  watch: {
    value(val) {
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false
        return
      }
      if (this.editor) this.editor.setContent(val)
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showImagePrompt(command) {
      this.command = command;
      this.isLibraryModalVisible = true;
    },
    openYoutubeModal(command) {
      this.$refs.ytmodal.showModal(command);
    },
    openSoundcloudModal(command) {
      this.$refs.scmodal.showModal(command);
    },
    openSourceCode() {
      this.$refs.sourcemodal.showModal(this.editor);
    },
    addYoutubeCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
    addSoundcloudCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
    addSourceCodeCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
    setContent() {
      this.editor.setContent(this.content);
    },
    updatePhoto(items) {
      this.selectedPhoto = items[0];
    },
    confirmNewPhoto() {
      this.command({ srcset: this.selectedPhoto.photo.medium, alt: this.selectedPhoto.photo.alt, title: this.selectedPhoto.caption });
      this.selectedPhoto = null;
      this.isLibraryModalVisible = false;
    },
  }

}
</script>

<template>
  <div
    v-lazyload
    class="editor"
  >
    <VideoModal
      ref="ytmodal"
      @onConfirm="addYoutubeCommand"
    />
    <SoundcloudModal
      ref="scmodal"
      @onConfirm="addSoundcloudCommand"
    />
    <SourceCodeModal
      ref="sourcemodal"
      @onConfirm="addSourceCodeCommand"
    />
    <editor-menu-bar
      v-slot="{ commands, isActive }"
      :editor="editor"
    >
      <div class="menubar">
        <div class="menubarTop">
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.bold() }"
            @click="commands.bold"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-bold"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.italic() }"
            @click="commands.italic"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-italic"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.underline() }"
            @click="commands.underline"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-underline"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.strike() }"
            @click="commands.strike"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-strikethrough"
            />
          </span>
          <span class="menubarButton separator" />
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.paragraph({ textAlign: 'left' }) }"
            @click="commands.paragraph({ textAlign: 'left' })"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-align-left"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.paragraph({ textAlign: 'center' }) }"
            @click="commands.paragraph({ textAlign: 'center' })"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-align-center"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.paragraph({ textAlign: 'right' }) }"
            @click="commands.paragraph({ textAlign: 'right' })"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-align-right"
            />
          </span>
          <span class="menubarButton separator" />
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-paragraph"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.heading({ level: 4 }) }"
            @click="commands.heading({ level: 4 })"
          >
            H4
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.heading({ level: 5 }) }"
            @click="commands.heading({ level: 5 })"
          >
            H5
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.heading({ level: 6 }) }"
            @click="commands.heading({ level: 6 })"
          >
            H6
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-list-ul"
            />
          </span>
          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-list-ol"
            />
          </span>

          <span
            class="menubarButton"
            :class="{ 'isActive': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-quote-left"
            />
          </span>
        </div>
        <div class="menubarBottom">
          <span
            class="menubarButton"
            @click="showImagePrompt(commands.customImage)"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-image"
            />
          </span>

          <span
            class="menubarButton"
            :title="$t('addItem', { item: `Youtube ${$tc('video')}` })"
            @click="openYoutubeModal(commands.iframe);"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-video"
            />
          </span>

          <span
            class="menubarButton"
            :title="$t('addItem', { item: `Soundcloud ${$t('player')}` })"
            @click="openSoundcloudModal(commands.soundcloud);"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-music"
            />
          </span>

          <span
            v-if="false"
            class="menubarButton"
            :title="$t('addItem', { item: $t('sourceCode') })"
            @click="openSourceCode();"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-code"
            />
          </span>

          <span
            class="menubarButton"
            @click="commands.horizontal_rule"
          >
            <BaseIcon
              icon="simple-delete"
            />
          </span>
          <span class="menubarButton separator" />
          <span
            class="menubarButton"
            @click="commands.undo"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-undo"
            />
          </span>
          <span
            class="menubarButton"
            @click="commands.redo"
          >
            <BaseIcon
              icons-group="fas"
              icon="fa-redo"
            />
          </span>
        </div>
      </div>
    </editor-menu-bar>
    <editor-content
      class="form-control editor"
      :editor="editor"
    />
    <TextEditorLink :editor="editor" />
    <Modal
      :show.sync="isLibraryModalVisible"
      :centered="true"
      :show-close="true"
    >
      <LibraryList
        v-if="isLibraryModalVisible"
        :limit="40"
        grid-item-size="100"
        select-items="single"
        class="library"
        @updateSelected="updatePhoto"
      />
      <BaseButton @click="confirmNewPhoto">
        {{ $t('confirm') }}
      </BaseButton>
    </Modal>
  </div>
</template>

<style lang="sass">
$s-blockquote: 60px

.editor .isEditorEmpty
  position: relative
  height: 400px
  &::before
    position: absolute
    top: 0
    left: 0
    font-style: italic
    color: transparentize(#f8f7f5, 0.8)
    content: 'Write Something'
strong
  font-weight: bold

blockquote
  position: relative
  display: flex
  align-items: center
  justify-content: center
  padding: 24px 12px
  margin: 24px 0
  color: #f8f7f5
  background: #11161c
  border-radius: 8px
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072)
  &::before
    position: absolute
    top: -$s-blockquote / 2
    left: 12px
    display: inline-block
    width: $s-blockquote
    height: $s-blockquote
    font-family: Courier, monospace
    font-size: 5rem
    line-height: 4.5rem
    color: #f8f7f5
    text-align: center
    vertical-align: middle
    content: '\201C'
    background: #39AF78
    border-radius: 50%
  &::after
    position: absolute
    right: 0
    bottom: 12px * 1.5
    left: 0
    width: 50%
    height: 1px
    margin: 0 auto
    content: ""
    background: transparentize(#39AF78, 0.8)

</style>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.separator
  display: inline
  border-left: 1px solid transparentize($text-faded, 0.8)
.menubar
  position: sticky
  top: 0
  z-index: $z-page
  padding: $base-spacing
  margin: 0 $s-xxs $s-m
  background: transparentize($c-pickled-bluewood-blue, 0.05)
  border-radius: 6px
  &Bottom
    margin-top: $s-s
  &Button
    display: inline-flex
    font-size: $s-m
    font-weight: 700
    color: $text-faded
    cursor: pointer
    &:hover
      color: $c-acc-green-dark
    &.isActive
      color: $c-acc-green
.menubarButton~.menubarButton
  margin-left: $s-m
.editor
  height: auto
  min-height: 400px
  max-height: unset
  font-size: 1rem

.library
  max-height: 90vh
  overflow: auto
</style>
