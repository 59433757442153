
<script>
export default {
    props: {
        mode: {
            type: String,
            default: 'light',
        },
    },
    data() {
        return {
            selectedIndex: 0, // the index of the selected tab,
            tabs: [], // all of the tabs
        }
    },
    created() {
        this.tabs = this.$children
    },
    mounted() {
        this.selectTab(0)
    },
    methods: {
        selectTab(i) {
            this.selectedIndex = i

            // loop over all the tabs
            this.tabs.forEach((tab, index) => {
                tab.isActive = index === i
            })
        },
    },
}
</script>

<template lang="html">
  <div :class="{'tabs__light': mode === 'light', 'tabs__dark': mode === 'dark'}">
    <ul class="headers">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        :class="['header', {'selected': (index == selectedIndex)}]"
        @click="selectTab(index)"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '~@/assets/sass/design';
.headers {
    display: block;
    list-style: none;
    margin: 0 0 0 $s-m;
    padding: 0;
}

.header {
    padding: $s-s $s-m;
    border-radius: 6px;
    margin: 0 $s-s 0 0;
    display: inline-block;
    cursor: pointer;
    color: $c-alto-grey;
    border: 1px solid transparentize($c-pickled-bluewood-blue, 0.05);

    &.selected {
        font-weight: bold;
        border-radius: 6px 6px 0 0;
        padding-bottom: $s-m;
        background-color: transparentize($c-pickled-bluewood-blue, 0.05);
        color: $c-acc-green;
    }
}
</style>
