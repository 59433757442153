import { render, staticRenderFns } from "./SoundcloudModal.vue?vue&type=template&id=99899de8&scoped=true"
import script from "./SoundcloudModal.vue?vue&type=script&lang=js"
export * from "./SoundcloudModal.vue?vue&type=script&lang=js"
import style0 from "./SoundcloudModal.vue?vue&type=style&index=0&id=99899de8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99899de8",
  null
  
)

export default component.exports